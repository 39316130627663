<template>

<div class="loan" v-on:click="onClick" :class="{'is-overdue': isOverdue}">

	<div class="loan-thumb" v-bgimage="loan.game.bgg_game.image"></div>
	
	<div class="loan-info">

		<div class="loan-info-name">{{ loan.game.name }}</div>
		<div class="loan-info-version" v-if="loan.game.version && loan.game.year">{{ loan.game.version }} ({{loan.game.year}})</div>
		<div class="loan-info-version" v-if="loan.game.version && !loan.game.year">{{ loan.game.version }}</div>
		<div class="loan-info-version" v-if="!loan.game.version && loan.game.year">{{ loan.game.year }}</div>
		<div class="loan-info-user">{{ loan.badge.name }}</div>

	</div>
	
	<div class="loan-time">{{ hours }}<span>hour<template v-if="hours !== 1">s</template></span></div>

</div>

</template>

<script>

export default {

	props: ['loan'],

	computed: {

		isOverdue: function() {

			return (this.$convention.settings.checkout.limit.hours.enabled) ? this.hours >= this.$convention.settings.checkout.limit.hours.amount : false

		},

		hours: function() {

			return Math.floor((this.$moment().utc().unix() - this.loan.date.out) / 3600)

		}

	},

	methods: {

		onClick: function() {

			this.$router.push({
				name: 'Checkout.Loan',
				params: {
					id: this.loan.id
				}
			})

		}

	}

}

</script>

<style scoped>

.loan {
	padding: 10px;
	border-bottom: 1px solid #ddd;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
}

.loan.is-overdue {
	background-color: #ffbaba;
}

.loan:last-child {
	border-bottom-width: 0px;
}

.loan-thumb {
	width: 48px;
	height: 48px;
	border-radius: 4px;
	background-position: 50% 50%;
	background-size: cover;
	background-color: #ccc;
	flex-shrink: 0;
}

.loan-info {
	flex-grow: 1;
	padding: 0px 10px;
}

.loan-info-name {
	font-size: 14px;
	font-weight: 400;
	color: #000;
}

.loan-info-version {
	font-size: 12px;
	font-weight: 300;
	color: #333;
	margin-top: 1px;
}

.loan-info-user {
	margin-top: 4px;
	font-size: 12px;
	font-weight: 500;
	color: #003278;
}

.loan-time {
	width: 48px;
	flex-shrink: 0;
	font-weight: 700;
	font-size: 24px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.loan-time span {
	display: block;
	font-size: 12px;
	font-weight: 300;
	color: #333;
}

</style>
